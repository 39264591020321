<template>
  <div class="main-box-container" :class="boxType" @click="openModal">
    <div class="filled-box">
      <div class="box-title">{{ title }}</div>
      <div
        class="box-data"
        @mouseover="showFullNumber"
        @mouseleave="hideFullNumber"
      >
        <span class="dollar-sign">
          $
        </span>
        <span class="total">{{ mainData }}</span>
      </div>
      <div class="box-data-full" v-if="fullData">
        <span class="dollar-sign">$</span>
        <span class="total">{{ fullData }}</span>
      </div>
      <div class="box-data-full" v-if="showingFullNumber && fullNumber">
        <span class="dollar-sign">$</span>
        <span class="total">{{ fullNumber }}</span>
          <span v-if="fullNumber2">  +  </span>
          <span v-if="fullNumber2" class="dollar-sign">$</span>
          <span v-if="fullNumber2" class="total">{{ fullNumber2 }}</span>
      </div>
    </div>
    <div class="highlight-box">
      {{ highlightData }}
      <div class="expand" v-if="hasDropdown">
        <FontAwesomeIcon icon="chevron-down" />
      </div>
    </div>
    <div class="icon-container" v-if="isActive">
      <div class="arrow" :class="boxType">
        <FontAwesomeIcon icon="chevron-down" />
      </div>
    </div>
  </div>
</template>

<script>
import { library } from "@fortawesome/fontawesome-svg-core";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(faChevronDown);

export default {
  name: "main-box",
  components: {
    FontAwesomeIcon
  },
  props: {
    title: "",
    mainData: "",
    highlightData: "",
    boxType: "",
    type: "",
    active: "",
    link: null,
    hasDropdown: false,
    fullData: null,
    fullNumber: null,
    fullNumber2: null
  },
  data() {
    return {
      showingFullNumber: false
    };
  },
  computed: {
    isActive() {
      return this.type ? this.type === this.active : false;
    }
  },
  methods: {
    openModal() {
      if (!this.link) {
        this.$emit("click", this.type);
      } else {
        const routeData = this.$router.resolve(this.link);
        window.open(routeData.href, "_blank").focus();
      }
    },
    showFullNumber() {
      this.showingFullNumber = true;
    },
    hideFullNumber() {
      this.showingFullNumber = false;
    }
  }
};
</script>
<style lang="less" scoped>
.main-box-container {
  position: relative;
  border-radius: 12px;
  text-align: center;
  aspect-ratio: 1;
  font-weight: bold;
  cursor: pointer;
  transition: 0.5s margin;
  user-select: none;

  &:active {
    margin: 4px;

    .filled-box > .box-data {
      font-size: 40px;
    }
  }
  .filled-box {
    height: 80%;
    padding: 8px 0;
    margin: 2px;
    border-radius: inherit;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .box-title {
      font-size: 12px;
    }
    .box-data {
      display: flex;
      margin: auto;
      font-size: 38px;
      transition: 0.5s;

      span.dollar-sign {
        font-size: 32px;
      }
    }
    .box-data-full {
      display: flex;
      font-size: 14px;
      transition: 0.5s;
      margin: -25px auto auto auto;

      span.dollar-sign {
        font-size: 10px;
      }
    }
  }
  .highlight-box {
    height: 15%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;

    .expand {
      margin-left: 16px;
    }
  }

  @media screen and (max-width: 900px) {
    .filled-box {
      .box-title {
        font-size: 8px;
      }
      .box-data {
        font-size: 12px;

        span.dollar-sign {
          font-size: 12px;
        }
      }
    }
    .highlight-box {
      font-size: 8px;
    }
  }

  @media screen and (max-width: 600px) {
    border-radius: 8px;
    .filled-box {
      .box-title {
        font-size: 6px;
        word-break: break-word;
        padding: 0px 4px;
      }
      .box-data,
      .box-data-full {
        font-size: 10px;

        span.dollar-sign {
          font-size: 8px;
        }
      }

      .box-data-full {
        font-size: 7px;
        margin: -4px auto auto auto;

        span.dollar-sign {
          font-size: 7px;
        }
      }
    }
    .highlight-box {
      font-size: 4px;
      padding: 0px 4px;
      height: initial;
      .expand {
        margin-left: 8px;
      }
    }
  }
}
// .main-box {
//   position: relative;
// }
.icon-container {
  text-align: center;
  position: absolute;
  right: 46%;
  .arrow {
    display: flex;
    justify-content: center;
    align-items: center;
    // background: #014a3a;
    width: 25px;
    height: 25px;
    border-radius: 4px;
    margin: auto;
    margin-top: 17px;
  }
}
.dark-green {
  background: #02734d;

  .filled-box {
    background: #014a3a;
  }

  &:last-child {
    background: #014a3a;

    .filled-box {
      background: #02734d;
    }
  }
}

.dark-red {
  background: #902249;
  .filled-box {
    background: #562439;
  }

  &:last-child {
    background: #562439;

    .filled-box {
      background: #902249;
    }
  }
}

.dark-orange {
  background: #a35501;

  .filled-box {
    background: #5f3d18;
  }

  &:last-child {
    background: #5f3d18;

    .filled-box {
      background: #a35501;
    }
  }
}

.dark-blue {
  background: #026f8b;

  .filled-box {
    background: #004858;
  }

  &:last-child {
    background: #004858;

    .filled-box {
      background: #026f8b;
    }
  }
}
</style>