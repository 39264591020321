export function nFormatter(num, digits) {
  if (num < 1) {
    return (num).toFixed(digits);
  }
  const lookup = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "k" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "B" }
    // { value: 1e12, symbol: "T" },
    // { value: 1e15, symbol: "P" },
    // { value: 1e18, symbol: "E" }
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var item = lookup
    .slice()
    .reverse()
    .find(function(item) {
      return num >= item.value;
    });

  if (num < 1000) {
    return item ? parseFloat(num / item.value).toFixed(digits) : "0.00";
  }
  return item ? (num / item.value).toFixed(digits) + item.symbol : "0.00";
}
