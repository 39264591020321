<template>
  <div id="Content">
    <div class="content-inner">
      <div class="content-path">
        <bread-crumbs :crumbs="breadcrumbs"/>
      </div>

      <InlineMessageBox :header="bulletPoint.title" site="buyer">
        <ul>
          <div v-for="(item, index) in bulletPoint.items" :key="index">
            <li>
              {{ item }}
            </li>
          </div>
        </ul>
      </InlineMessageBox>
      <div class="financial-boxes-container" v-if="!loading">
        <access-sales
          :data="access"
          @active="active"
          :class="{ dim: !isActive('accessSales') && !allDeactive }"
        />
        <special-sales
          :data="specials"
          @active="active"
          :class="{ dim: !isActive('specialSales') && !allDeactive }"
        />
        <!--        <ticket-sales-->
        <!--          :data="revenue"-->
        <!--          @active="active"-->
        <!--          :class="{ dim: !isActive('ticketSales') && !allDeactive }"-->
        <!--        />-->
        <refunds
          :data="expenses"
          @active="active"
          :class="{ dim: !isActive('refunds') && !allDeactive }"
        />
        <processing-fees
          @active="active"
          :data="fees"
          :class="{ dim: !isActive('processingFees') && !allDeactive }"
        />
        <total
          @active="active"
          :data="net"
          :class="{ dim: !isActive('netRevenue') && !allDeactive }"
          :buyerOrPlannerPaysFees="buyerOrPlannerPaysFees"
        />
      </div>
    </div>
  </div>
</template>

<script>
import MainBox from "../components/financials/MainBox.vue";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import {library} from "@fortawesome/fontawesome-svg-core";
import {faEquals, faMinus, faPlus} from "@fortawesome/free-solid-svg-icons";
import TicketSales from "../components/financials/TicketSales.vue";
import Refunds from "../components/financials/Refunds.vue";
import Total from "../components/financials/Total.vue";
import ProcessingFees from "../components/financials/ProcessingFees.vue";
import {nFormatter} from "../helpers/numbers";
import BreadCrumbs from "@/components/Breadcrumbs.vue";
import InlineMessageBox from "@/components/InlineMessageBox.vue";
import AccessSales from "../components/financials/AccessSales.vue";
import SpecialSales from "../components/financials/SpecialSales.vue";

library.add(faMinus, faPlus, faEquals);

export default {
  name: "financials",
  components: {
    MainBox,
    FontAwesomeIcon,
    TicketSales,
    Refunds,
    Total,
    ProcessingFees,
    BreadCrumbs,
    InlineMessageBox,
    AccessSales,
    SpecialSales
  },
  data() {
    return {
      bulletPoint: {
        title: "Understanding your financials",
        items: []
      },
      breadcrumbs: [
        ["Dashboard", "/dashboard"],
        ["Financials", null]
      ],
      buyerOrPlannerPaysFees: null,
      tiles: [
        {tile: "ticketSales", value: false},
        {tile: "accessSales", value: false},
        {tile: "specialSales", value: false},
        {tile: "refunds", value: false},
        {tile: "processingFees", value: false},
        {tile: "netRevenue", value: false}
      ],
      eventName: "",
      financialType: "",
      data: {},
      access: {},
      specials: {},
      revenue: {},
      expenses: {},
      fees: {},
      net: {
        gross_revenue: {
          total: 0.0,
          count: 0,
        },
        gross_expenses: {},
        gross_fees: {},
        proprietary_work: {
          total: 0.0,
          count: 0,
          highlight_text: "Projects"
        },
        net_revenue: {
          total: 0.0,
          count: 0,
          highlight_text: "Total Purchases"
        }
      },
      loading: false
    };
  },
  computed: {
    allDeactive() {
      return this.tiles.every(t => t.value === false);
    }
  },
  methods: {
    active(type) {
      this.tiles.forEach(ele => {
        if (ele.tile != type) {
          ele.value = false;
        } else {
          ele.value = !ele.value;
        }
      });
    },
    isActive(type) {
      return this.tiles.tile === type;
    },
    async getAccess() {
      const {data} = await this.$axios.get(
        `/planners/financials/${this.$route.params.event}/calculate-access`
      );
      this.buyerOrPlannerPaysFees = data.buyer_planner_pays_fees_taxes;
      this.bulletPoint.items.push(data.financial_type);
      this.eventName = data.event_name;
      let financials = data.financials;

      for (const key in financials) {
        if (Object.hasOwnProperty.call(financials, key)) {
          let element = financials[key];

          // Add to Total
          if (key === "total_sales") {
            this.net.gross_revenue = {...element};

            element.count = `${nFormatter(element.ticket_count, 0)} ${
              element.highlight_text_1
            }/${nFormatter(element.product_count, 0)} ${
              element.highlight_text_2
            }`;
          } else {
            element.count = `${element.count} ${
              element.highlight_text
            }`;
          }
          if (element.taxes) {
            element.fullData = parseFloat(element.total - element.taxes).toFixed(2);
            element.fullData2 = parseFloat(element.taxes).toFixed(2)
          } else {
            element.fullData = parseFloat(element.total).toFixed(2);
          }

          element.total = nFormatter(element.total, 2);
        }
      }
      this.access = financials;
    },
    async getSpecial() {
      const {data} = await this.$axios.get(
        `/planners/financials/${this.$route.params.event}/calculate-special-sales`
      );
      let financials = data.financials;

      for (const key in financials) {
        if (Object.hasOwnProperty.call(financials, key)) {
          let element = financials[key];
          if (key === "total_sales") {
            this.net.gross_revenue.count += element.count;
            this.net.gross_revenue.total += element.total;
          }
          // Add to Total
          element.count = `${element.count} ${
            element.highlight_text
          }`;
          element.fullData = parseFloat(element.total).toFixed(2);
          element.total = nFormatter(element.total, 2);
        }
      }
      this.specials = financials;
    },
    async getRevenue() {
      const {data} = await this.$axios.get(
        `/planners/financials/${this.$route.params.event}/calculate-revenue`
      );
      this.buyerOrPlannerPaysFees = data.buyer_planner_pays_fees_taxes;
      this.bulletPoint.items.push(data.financial_type);
      this.eventName = data.event_name;
      let financials = data.financials;

      for (const key in financials) {
        if (Object.hasOwnProperty.call(financials, key)) {
          let element = financials[key];

          // Add to Total
          if (key === "total_sales") {
            this.net.gross_revenue = {...element};

            element.count = `${nFormatter(element.ticket_count, 0)} ${
              element.highlight_text_1
            }/${nFormatter(element.product_count, 0)} ${
              element.highlight_text_2
            }`;
          } else {
            element.count = `${nFormatter(element.count, 0)} ${
              element.highlight_text
            }`;
          }
          if (element.taxes) {
            element.fullData = parseFloat(element.total - element.taxes).toFixed(2);
            element.fullData2 = parseFloat(element.taxes).toFixed(2)
          } else {
            element.fullData = parseFloat(element.total).toFixed(2);
          }

          element.total = nFormatter(element.total, 2);
        }
      }
      this.revenue = financials;
    },
    async getExpenses() {
      let {data} = await this.$axios.get(
        `/planners/financials/${this.$route.params.event}/calculate-expenses`
      );

      for (const key in data) {
        if (Object.hasOwnProperty.call(data, key)) {
          let element = data[key];

          if (key === "total_expenses") {
            this.net.gross_expenses = {...element};
          }

          element.count = `${nFormatter(element.count, 0)} ${
            element.highlight_text
          }`;
          element.fullData = parseFloat(element.total).toFixed(2);
          element.total = nFormatter(element.total, 2);
        }
      }

      this.expenses = data;
    },
    async getFees() {
      let {data} = await this.$axios.get(
        `/planners/financials/${this.$route.params.event}/fees-tiles`
      );
      for (const key in data) {
        if (Object.hasOwnProperty.call(data, key)) {
          let element = data[key];

          if (key === "gross_fees") {
            this.net.gross_fees = {...element};
          }
          element.fullData = parseFloat(element.total).toFixed(2);
          element.total = nFormatter(element.total, 2);
        }
      }
      this.fees = data;
    },
    calculateNetRevenue() {
      let net_revenue = 0;
      let net_count = 0;
      for (const key in this.net) {
        if (Object.hasOwnProperty.call(this.net, key)) {
          let element = this.net[key];

          if (key === "gross_revenue") {
            net_count += element.count;
            net_revenue += element.total;
          }
          if (key === "gross_expenses") {
            net_revenue -= element.total;
          }
          if (key === "gross_fees") {
            if (this.buyerOrPlannerPaysFees !== "buyerPaysFees") {
              net_revenue -= element.total;
            }
          }

          if (key === "net_revenue") {
            element.total = net_revenue;
            element.count = net_count;
            element.full = element.total.toFixed(2);
          }

          if (key !== "gross_fees") {
            element.count = `${nFormatter(element.count, 0)} ${
              element.highlight_text
            }`;
          }
          element.fullData = parseFloat(element.total).toFixed(2);
          element.total = nFormatter(element.total, 2);
        }
      }
    }
  },
  async created() {
    this.loading = true;
     await this.$axios
      .get(`/planners/financials/${this.$route.params.event}/check-permissions`)
      .then()
      .catch(e => {
        this.$router.push(`/dashboard/current-events`);
      });
    await this.getAccess();
    await this.getSpecial();
    // await this.getRevenue();
    await this.getExpenses();
    await this.getFees();
    this.calculateNetRevenue();

    this.loading = false;
    // this.$bus.$emit('alert', {title: 'Attention!', msg: "We are currently experiencing connection issues with our payment processor and that is affecting financial reporting. We will update you once they have deployed a fix."})
    this.$store.commit("setTitle", this.eventName);
    this.$store.commit("setSubtitle", "Financials");
  }
};
</script>

<style lang="less" scoped>
.financial-boxes-container {
  margin-top: 40px;
  margin-bottom: 60px;
  display: grid;
  width: 100%;
  column-gap: 8px;
  row-gap: 40px;
  // grid-template-columns: 1fr 30px 1fr 30px 1fr 30px 1fr 30px 1fr;

  .ticket-sales-container.dim:not(.active) {
    opacity: 0.2;
  }
}

.plus,
.minus,
.equals {
  width: 30px;
  height: 30px;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #494e59;
  border-radius: 50%;
  font-size: 16px;
  font-weight: bold;
}
</style>
