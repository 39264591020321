import { render, staticRenderFns } from "./AccessSales.vue?vue&type=template&id=88202488&scoped=true&"
import script from "./AccessSales.vue?vue&type=script&lang=js&"
export * from "./AccessSales.vue?vue&type=script&lang=js&"
import style0 from "./AccessSales.vue?vue&type=style&index=0&id=88202488&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "88202488",
  null
  
)

export default component.exports