<template>
  <div class="carousel-container" :class="{ 'has-pagination': showNavigation }">
    <div class="page-left" @click="paginate('backward')">
      <FontAwesomeIcon icon="angle-left" />
    </div>
    <Carousel
      ref="financialCarousel"
      pagination-active-color="#2bacd9"
      v-model="page"
      :per-page-custom="breakpoints"
    >
      <Slide v-for="(tier, index) in data" :key="index">
        <secondary-box
          :boxType="color"
          :title="tier.name"
          :mainData="tier.total"
          :highlightData="tier.count"
          :highlightText="tier.highlight_text"
        />
        <!-- <MerchCarouselItem :item="item" :eventCurrency="eventCurrency" /> -->
      </Slide>
    </Carousel>
    <div class="page-right" @click="paginate('forward')">
      <FontAwesomeIcon icon="angle-right" />
    </div>
  </div>
</template>

<script>
import SecondaryBox from "./SecondaryBox.vue";
import Carousel from "vue-carousel/src/Carousel.vue";
import Slide from "vue-carousel/src/Slide.vue";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
library.add(faAngleLeft, faAngleRight);

export default {
  name: "box-carousel",
  props: {
    data: [],
    color: {
      type: String,
      default: 'dark-green'
    }
  },
  components: { SecondaryBox, Carousel, Slide, FontAwesomeIcon },
  data() {
    return {
      page: 0,
      breakpoints: [
        [0, 3],
        [650, 4],
        [900, 5],
        [1200, 5]
      ],
      showNavigation: false,
      componentName: "ticketSales",
      title: "Ticket Sales",
      secondary: false,
      tiersData: []
    };
  },
  methods: {
    paginate(direction) {
      this.$refs.financialCarousel.advancePage(direction);
    }
  },
  beforeUpdate() {
    if (this.$refs.financialCarousel) {
      this.showNavigation = this.$refs.financialCarousel.isNavigationRequired;
    }
  }
};
</script>

<style lang="less" scoped>
.carousel-container {
  position: relative;
  .page-left {
    position: absolute;
    top: 37%;
    left: -19px;
    font-size: 40px;
    cursor: pointer;
  }

  .page-right {
    position: absolute;
    top: 37%;
    right: -19px;
    font-size: 40px;
    cursor: pointer;
  }

  // &.has-pagination {
  //   margin-bottom: 40px;
  // }
  &::v-deep .VueCarousel {
    &-pagination {
      position: absolute;
      bottom: -50px;

      button {
        margin-top: 0 !important;
      }
    }

    &-dot-container {
      margin-top: 0 !important;
    }

    &-wrapper {
      overflow: hidden visible;
    }
  }
}

.box-carousel {
  display: flex;
  justify-content: flex-start;
  column-gap: 46px;
  overflow: scroll;
}

.left,
.right {
  position: absolute;
  font-size: 25px;
  font-weight: bold;
  width: 32px;
  height: 32px;
  background: red;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.left {
  left: -40px;
  bottom: 44%;
}
.right {
  right: -40px;
  bottom: 44%;
}
</style>