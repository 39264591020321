<template>
  <div
    class="ticket-sales-container"
    :class="{ active: secondary }"
    v-click-outside="close"
  >
    <!-- @click="toggle" -->
    <div class="financial-box-container">
      <main-box
        :boxType="color"
        title="Gross Revenue"
        :mainData="data.gross_revenue.total"
        :fullNumber="data.gross_revenue.fullData"
        :highlightData="data.gross_revenue.count"
      />
      <div class="minus">
        <FontAwesomeIcon icon="minus" size="sm" />
      </div>
      <main-box
        :boxType="color"
        title="Gross Expenses"
        :mainData="data.gross_expenses.total"
        :fullNumber="data.gross_expenses.fullData"
        :highlightData="data.gross_expenses.count"
      />
      <div class="minus">
        <FontAwesomeIcon icon="minus" size="sm" />
      </div>
      <main-box
        :boxType="color"
        title="Gross Fees"
        :mainData="buyerOrPlannerPaysFees === 'buyerPaysFees' ? '0.00' : data.gross_fees.total"
        :fullNumber="buyerOrPlannerPaysFees === 'buyerPaysFees' ? '0.00' : data.gross_fees.fullData"
        :highlightData="buyerOrPlannerPaysFees === 'buyerPaysFees' ? '0 Fees Total' : data.gross_fees.highlight"
      />
      <div class="minus">
        <FontAwesomeIcon icon="minus" size="sm" />
      </div>
      <main-box
        :boxType="color"
        title="Proprietary Work"
        :mainData="data.proprietary_work.total"
        :fullNumber="data.proprietary_work.fullData"
        :highlightData="data.proprietary_work.count"
      />
      <div class="equals">
        <FontAwesomeIcon icon="equals" size="sm" />
      </div>
      <main-box
        :boxType="color"
        title="Net Revenue"
        :mainData="data.net_revenue.total"
        :fullData="data.net_revenue.full"
        :highlightData="data.net_revenue.count"
      />
    </div>
  </div>
</template>

<script>
import MainBox from "./MainBox.vue";
import SecondaryBox from "./SecondaryBox";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faMinus, faPlus, faEquals } from "@fortawesome/free-solid-svg-icons";

library.add(faMinus, faPlus, faEquals);

export default {
  name: "total",
  props: {
    data: Object,
    buyerOrPlannerPaysFees: String
  },
  data() {
    return {
      color: "dark-blue",
      title: "Net Revenue",
      componentName: "netRevenue",
      secondary: false
    };
  },
  components: { MainBox, FontAwesomeIcon, SecondaryBox },
  methods: {
    toggle() {
      this.secondary = !this.secondary;
      this.$emit("active", this.componentName);
    },
    close() {
      if (this.secondary) {
        this.secondary = false;
        this.$emit("active", this.componentName);
      }
    }
  }
};
</script>
<style lang="less" scoped>
.ticket-sales-container {
  display: grid;
  width: 100%;
  column-gap: 8px;
  row-gap: 40px;
  position: relative;

  &.active {
    grid-template-rows: repeat(2, 1fr);

    .financial-box-container > .main-box-container {
      opacity: 1;
    }
  }
}
.financial-box-container {
  display: grid;
  width: 100%;
  column-gap: 8px;
  row-gap: 40px;
  grid-template-columns: 1fr 30px 1fr 30px 1fr 30px 1fr 30px 1fr;
  transition: all 0.5s ease-in-out;

  @media screen and (max-width: 900px) {
    column-gap: initial;
    grid-template-columns: 1fr 15px 1fr 15px 1fr 15px 1fr 15px 1fr;
  }
  &.secondary {
    opacity: 0;
    position: absolute;
    bottom: 100px;
    z-index: -1;
  }

  &.active {
    opacity: 1;
    bottom: 0;
    z-index: 1;
    // display: grid;
  }

  &.hide {
    .main-box-container {
      opacity: 0.3;
    }
  }
}

.plus,
.minus,
.equals {
  width: 30px;
  height: 30px;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #494e59;
  border-radius: 50%;
  font-size: 16px;
  font-weight: bold;

  @media screen and (max-width: 900px) {
    width: 10px;
    height: 10px;
    font-size: 6px;
  }
}
</style>
