<template>
  <div
    class="ticket-sales-container"
    :class="{ active: secondary }"
    v-click-outside="close"
  >
    <div class="financial-box-container">
      <main-box
        :boxType="color"
        title="Refunds"
        :mainData="data.refunds.total"
        :fullNumber="data.refunds.fullData"
        :highlightData="data.refunds.count"
      />
      <div class="plus">
        <FontAwesomeIcon icon="plus" size="sm" />
      </div>
      <main-box
        :boxType="color"
        title="Comps & Will Call"
        :mainData="data.comps.total"
        :link="`/comps/${eventUrl}`"
        :highlightData="data.comps.count"
        :fullNumber="data.comps.fullData"
      />
      <div class="plus">
        <FontAwesomeIcon icon="plus" size="sm" />
      </div>
      <main-box
        @click="toggle"
        :active="activeType"
        :boxType="color"
        type="misc"
        title="Misc"
        :mainData="data.misc_admin.total"
        :highlightData="data.misc_admin.count"
        :fullNumber="data.misc_admin.fullData"
        :hasDropdown="hasDropdown('misc')"
      />
      <div class="plus">
        <FontAwesomeIcon icon="plus" size="sm" />
      </div>
      <main-box
        :boxType="color"
        title="Commissions"
        :link="`/promoters/${eventUrl}/financials`"
        :mainData="data.commissions.total"
        :highlightData="data.commissions.count"
        :fullNumber="data.commissions.fullData"
      />
      <div class="equals">
        <FontAwesomeIcon icon="equals" size="sm" />
      </div>
      <main-box
        :boxType="color"
        title="Gross Expense"
        :mainData="data.total_expenses.total"
        :fullNumber="data.total_expenses.fullData"
        :highlightData="data.total_expenses.count"
      />
    </div>
    <div
      class="financial-box-container secondary"
      :class="{ hidden: !secondary, active: secondary }"
    >
      <box-carousel :data="activeData" :color="color"/>
    </div>
  </div>
</template>

<script>
import MainBox from "./MainBox.vue";
import SecondaryBox from "./SecondaryBox";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faMinus, faPlus, faEquals } from "@fortawesome/free-solid-svg-icons";
import { nFormatter } from "../../helpers/numbers";
import BoxCarousel from "./BoxCarousel.vue";
library.add(faMinus, faPlus, faEquals);

export default {
  name: "Refunds",
  props: {
    open: Boolean,
    hidden: Boolean,
    data: Object
  },
  computed: {
    eventUrl() {
      return this.$route.params.event;
    }
  },
  data() {
    return {
      componentName: "refunds",
      color: "dark-red",
      title: "Refunds",
      refunds: {
        count: 0,
        total: 0
      },
      comps: {
        count: 0,
        total: 0
      },
      misc_admin: {
        count: 0,
        total: 0
      },
      commissions: {
        count: 0,
        total: 0
      },
      total_expenses: {
        count: 0,
        total: 0
      },
      secondary: false,
      tilesData: [],
      miscData: [],
      activeType: "",
      activeData: []
    };
  },
  components: { MainBox, FontAwesomeIcon, SecondaryBox, BoxCarousel },
  methods: {
    toggle(type) {
      if (!this.secondary) {
        this.secondary = true;
        this.$emit("active", this.componentName);
        this.switchPage(type);
      } else if (this.secondary && this.activeType !== type) {
        this.switchPage(type);
      } else if (this.secondary && this.activeType === type) {
        this.close();
      }
    },
    hasDropdown(type) {
      let value = false;
      switch (type) {
        case "misc":
          value = this.miscData.length > 0 ? true : false;
          break;
        default:
          value = false;
          break;
      }

      return value;
    },
    switchPage(type) {
      switch (type) {
        case "tickets":
          this.activeData = this.miscData;
          break;
        default:
          this.activeData = this.miscData;
          break;
      }
      if (this.activeData.length > 0) {
        this.activeType = type;
      } else {
        this.$toast("No data available for option selected.", {
          type: "error"
        });
        this.close();
      }
    },
    close() {
      this.activeType = "";
      this.activeData = [];

      if (this.secondary) {
        this.secondary = false;
        this.$emit("active", this.componentName);
      }
    }
  },
  mounted() {
    this.refunds = this.data.refunds;
    this.comps = this.data.comps;
    this.misc_admin = this.data.misc_admin;
    this.commissions = this.data.commissions;
    this.total_expenses = this.data.total_expenses;
    this.$axios
        .get(`/planners/financials/${this.$route.params.event}/get-misc-data`)
        .then(({ data }) => {
          data.misc.forEach(element => {
            element.count = `${nFormatter(element.count, 0)} ${
                element.highlight_text
            }`;
            element.fullData = element.total;
            element.total = nFormatter(element.total, 2);
          });
          this.miscData = data.misc;
        });

  }
};
</script>
<style lang="less" scoped>
.ticket-sales-container {
  display: grid;
  width: 100%;
  column-gap: 8px;
  row-gap: 50px;
  position: relative;

  &.active {
    grid-template-rows: repeat(2, 1fr);
    margin-bottom: 40px;

    .financial-box-container > .main-box-container {
      opacity: 1;
    }
  }
}
.financial-box-container {
  display: grid;
  width: 100%;
  column-gap: 8px;
  row-gap: 40px;
  grid-template-columns: 1fr 30px 1fr 30px 1fr 30px 1fr 30px 1fr;
  transition: all 0.3s ease-in-out;

  @media screen and (max-width: 900px) {
    grid-template-columns: 1fr 15px 1fr 15px 1fr 15px 1fr 15px 1fr;
    column-gap: initial;
  }
  &.secondary {
    display: block;
    opacity: 0;
    position: absolute;
    bottom: 50px;
    z-index: -1;
  }

  &.active {
    opacity: 1;
    bottom: 0px;
    z-index: 1;
    // display: grid;
  }

  &.hide {
    .main-box-container {
      opacity: 0.3;
    }
  }
}

.plus,
.minus,
.equals {
  width: 30px;
  height: 30px;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #494e59;
  border-radius: 50%;
  font-size: 16px;
  font-weight: bold;

  @media screen and (max-width: 900px) {
    width: 10px;
    height: 10px;
    font-size: 6px;
  }
}
</style>