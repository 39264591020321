<template>
  <div
    class="ticket-sales-container"
    :class="{ active: secondary }"
    v-click-outside="close"
  >
    <div class="financial-box-container">
      <main-box
        :boxType="color"
        title="Admin Fees"
        :mainData="data.admin_fee.total"
        :fullNumber="data.admin_fee.fullData"
        :highlightData="data.admin_fee.highlight"
      />
      <div class="plus">
        <FontAwesomeIcon icon="plus" size="sm" />
      </div>
      <main-box
        :boxType="color"
        title="Processing Fees"
        :mainData="data.processing_fee.total"
        :fullNumber="data.processing_fee.fullData"
        :highlightData="data.processing_fee.highlight"
      />
      <div class="plus">
        <FontAwesomeIcon icon="plus" size="sm" />
      </div>
      <main-box
        :boxType="color"
        title="Admin Taxes"
        :mainData="data.admin_taxes.total"
        :fullNumber="data.admin_taxes.fullData"
        :highlightData="data.admin_taxes.highlight"
      />
      <div class="plus">
        <FontAwesomeIcon icon="plus" size="sm" />
      </div>
      <main-box
        :boxType="color"
        title="Custom Fees"
        :mainData="data.custom_fee.total"
        :fullNumber="data.custom_fee.fullData"
        :highlightData="data.custom_fee.highlight"
      />
      <div class="plus">
        <FontAwesomeIcon icon="equals" size="sm" />
      </div>
      <main-box
        :boxType="color"
        title="Gross Fees"
        :mainData="data.gross_fees.total"
        :fullNumber="data.gross_fees.fullData"
        :highlightData="data.gross_fees.highlight"
      />
    </div>
    <div class="financial-box-container" v-if="secondary">
      <secondary-box
        :boxType="color"
        :title="title"
        mainData="31.6k"
        highlightData="10k"
      />
      <div class="plus">
        <FontAwesomeIcon icon="plus" size="sm" />
      </div>
      <secondary-box
        :boxType="color"
        :title="title"
        mainData="31.7k"
        highlightData="10k"
      />
      <div class="plus">
        <FontAwesomeIcon icon="plus" size="sm" />
      </div>
      <secondary-box
        :boxType="color"
        :title="title"
        mainData="31.8k"
        highlightData="10k"
      />
      <div class="plus">
        <FontAwesomeIcon icon="plus" size="sm" />
      </div>
      <secondary-box
        :boxType="color"
        :title="title"
        mainData="31.9k"
        highlightData="10k"
      />
      <div class="plus">
        <FontAwesomeIcon icon="plus" size="sm" />
      </div>
      <secondary-box
        :boxType="color"
        :title="title"
        mainData="32k"
        highlightData="10k"
      />
    </div>
  </div>
</template>

<script>
import MainBox from "./MainBox.vue";
import SecondaryBox from "./SecondaryBox";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faMinus, faPlus, faEquals } from "@fortawesome/free-solid-svg-icons";
import { nFormatter } from "../../helpers/numbers";

library.add(faMinus, faPlus, faEquals);

export default {
  name: "processing-fees",
  props: {
    data: Object
  },
  data() {
    return {
      color: "dark-orange",
      componentName: "processingFees",
      title: "Processing Fees",
      secondary: false,
      admin_fee: 0,
      processing_fee: 0,
      admin_taxes: 0,
      custom_fee: 0,
      gross_fees: 0
    };
  },
  components: { MainBox, FontAwesomeIcon, SecondaryBox },
  methods: {
    toggle() {
      this.secondary = !this.secondary;
      this.$emit("active", this.componentName);
    },
    close() {
      if (this.secondary) {
        this.secondary = false;
        this.$emit("active", this.componentName);
      }
    }
  },
  mounted() {
    this.admin_fee = this.data.admin_fee;
    this.processing_fee = this.data.processing_fee;
    this.admin_taxes = this.data.admin_taxes;
    this.custom_fee = this.data.custom_fee;
    this.gross_fees = this.data.gross_fees;
  }
};
</script>
<style lang="less" scoped>
.ticket-sales-container {
  display: grid;
  width: 100%;
  column-gap: 8px;
  row-gap: 40px;
  position: relative;

  &.active {
    grid-template-rows: repeat(2, 1fr);

    .financial-box-container > .main-box-container {
      opacity: 1;
    }
  }
}

.financial-box-container {
  display: grid;
  width: 100%;
  column-gap: 8px;
  row-gap: 40px;
  grid-template-columns: 1fr 30px 1fr 30px 1fr 30px 1fr 30px 1fr;
  transition: all 0.5s ease-in-out;

  @media screen and (max-width: 900px) {
    column-gap: initial;
    grid-template-columns: 1fr 15px 1fr 15px 1fr 15px 1fr 15px 1fr;
  }
  &.secondary {
    opacity: 0;
    position: absolute;
    bottom: 100px;
    z-index: -1;
  }

  &.active {
    opacity: 1;
    bottom: 0;
    z-index: 1;
    // display: grid;
  }

  &.hide {
    .main-box-container {
      opacity: 0.3;
    }
  }
}

.plus,
.minus,
.equals {
  width: 30px;
  height: 30px;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #494e59;
  border-radius: 50%;
  font-size: 16px;
  font-weight: bold;

  @media screen and (max-width: 900px) {
    width: 10px;
    height: 10px;
    font-size: 6px;
  }
}
</style>