<template>
  <div
    class="ticket-sales-container"
    :class="{ active: secondary }"
    v-click-outside="close"
  >
    <div class="financial-box-container">
      <main-box
        @click="toggle"
        :boxType="color"
        :active="activeType"
        type="tickets"
        title="Access Sales + Taxes"
        :fullNumber="data.ticket_sales.fullData"
        :fullNumber2="data.ticket_sales.fullData2 || null"
        :mainData="data.ticket_sales.total"
        :highlightData="data.ticket_sales.count"
        :hasDropdown="hasDropdown('tickets')"
      />
      <div class="plus">
        <FontAwesomeIcon icon="plus" size="sm" />
      </div>
      <main-box
        @click="toggle"
        :boxType="color"
        :active="activeType"
        type="products"
        title="Product Sales + Taxes"
        :fullNumber="data.product_sales.fullData"
        :mainData="data.product_sales.total"
        :highlightData="data.product_sales.count"
        :hasDropdown="hasDropdown('products')"
      />
      <div class="plus">
        <FontAwesomeIcon icon="plus" size="sm" />
      </div>
      <main-box
        :boxType="color"
        @click="toggle"
        type="discounts"
        :active="activeType"
        title="Discounted Sales"
        :mainData="data.discounted_sales.total"
        :fullNumber="data.discounted_sales.fullData"
        :highlightData="data.discounted_sales.count"
        :hasDropdown="hasDropdown('discounts')"
      />
      <div class="plus">
        <FontAwesomeIcon icon="plus" size="sm" />
      </div>
      <main-box
        :boxType="color"
        @click="toggle"
        type="promoters"
        :active="activeType"
        title="Promoter Sales"
        :mainData="data.promoter_sales.total"
        :fullNumber="data.promoter_sales.fullData"
        :highlightData="data.promoter_sales.count"
        :hasDropdown="hasDropdown('promoters')"
      />
      <div class="equals">
        <FontAwesomeIcon icon="equals" size="sm" />
      </div>
      <main-box
        :boxType="color"
        type="gross"
        title="Gross Revenue"
        :mainData="data.total_sales.total"
        :fullNumber="data.total_sales.fullData"
        :highlightData="data.total_sales.count"
      />
    </div>
    <div
      class="financial-box-container secondary"
      :class="{ hidden: !secondary, active: secondary }"
    >
      <box-carousel :data="activeData" />

      <!-- <div class="plus" >
        <FontAwesomeIcon icon="plus" size="sm" />
      </div> -->
    </div>
  </div>
</template>

<script>
import MainBox from "./MainBox.vue";
import SecondaryBox from "./SecondaryBox";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faMinus, faPlus, faEquals } from "@fortawesome/free-solid-svg-icons";
import { nFormatter } from "../../helpers/numbers";
import BoxCarousel from "./BoxCarousel.vue";
library.add(faMinus, faPlus, faEquals);

export default {
  name: "revenue",
  props: {
    open: Boolean,
    hidden: Boolean,
    data: Object
  },
  data() {
    return {
      componentName: "ticketSales",
      color: "dark-green",
      title: "Ticket Sales",
      secondary: false,
      tiersData: [],
      productsData: [],
      discountsData: [],
      promotersData: [],
      activeType: "",
      activeData: []
    };
  },
  components: { MainBox, FontAwesomeIcon, SecondaryBox, BoxCarousel },
  methods: {
    toggle(type) {
      if (!this.secondary) {
        this.secondary = true;
        this.$emit("active", this.componentName);
        this.switchPage(type);
      } else if (this.secondary && this.activeType !== type) {
        this.switchPage(type);
      } else if (this.secondary && this.activeType === type) {
        this.close();
      }
    },
    hasDropdown(type) {
      let value = false;
      switch (type) {
        case "tickets":
          value = this.tiersData.length > 0 ? true : false;
          break;
        case "products":
          value = this.productsData.length > 0 ? true : false;
          break;
        case "discounts":
          value = this.discountsData.length > 0 ? true : false;
          break;
        case "promoters":
          value = this.promotersData.length > 0 ? true : false;
          break;
        default:
          value = false;
          break;
      }

      return value;
    },
    switchPage(type) {
      switch (type) {
        case "tickets":
          this.activeData = this.tiersData;
          break;
        case "products":
          this.activeData = this.productsData;
          break;
        case "discounts":
          this.activeData = this.discountsData;
          break;
        case "promoters":
          this.activeData = this.promotersData;
          break;
        default:
          this.activeData = this.tiersData;
          break;
      }
      if (this.activeData.length > 0) {
        this.activeType = type;
      } else {
        this.$toast("No data available for option selected.", {
          type: "error"
        });
        this.close();
      }
    },
    close() {
      this.activeType = "";
      this.activeData = [];

      if (this.secondary) {
        this.secondary = false;
        this.$emit("active", this.componentName);
      }
    }
  },
  async mounted() {
    this.$axios
      .get(`/planners/financials/${this.$route.params.event}/get-tier-sales`)
      .then(({ data }) => {
        data.tiers.forEach(element => {
          element.count = `${nFormatter(element.count, 0)} ${
            element.highlight_text
          }`;

          this.$set(element, "fullData", element.total);

          element.total = nFormatter(element.total, 2);
        });

        this.tiersData = data.tiers;
      });

    this.$axios
      .get(`/planners/financials/${this.$route.params.event}/get-product-sales`)
      .then(({ data }) => {
        data.products.forEach(element => {
          element.count = `${nFormatter(element.count, 0)} ${
            element.highlight_text
          }`;
          element.fullData = element.total;
          element.total = nFormatter(element.total, 2);
        });

        this.productsData = data.products;
      });

    this.$axios
      .get(
        `/planners/financials/${this.$route.params.event}/get-discount-sales`
      )
      .then(({ data }) => {
        data.discounts.forEach(element => {
          element.count = `${nFormatter(element.count, 0)} ${
            element.highlight_text
          }`;
          element.fullData = element.total;
          element.total = nFormatter(element.total, 2);
        });
        this.discountsData = data.discounts;
      });

    this.$axios
      .get(
        `/planners/financials/${this.$route.params.event}/get-promoter-sales`
      )
      .then(({ data }) => {
        data.promoters.forEach(element => {
          element.count = `${nFormatter(element.count, 0)} ${
            element.highlight_text
          }`;
          element.fullData = element.total;
          element.total = nFormatter(element.total, 2);
        });
        this.promotersData = data.promoters;
      });
  }
};
</script>
<style lang="less" scoped>
.ticket-sales-container {
  display: grid;
  width: 100%;
  column-gap: 8px;
  row-gap: 50px;
  position: relative;

  &.active {
    grid-template-rows: repeat(2, 1fr);
    margin-bottom: 40px;

    .financial-box-container > .main-box-container {
      opacity: 1;
    }
  }
}
.financial-box-container {
  display: grid;
  width: 100%;
  column-gap: 8px;
  row-gap: 40px;
  grid-template-columns: 1fr 30px 1fr 30px 1fr 30px 1fr 30px 1fr;
  transition: all 0.3s ease-in-out;

  @media screen and (max-width: 900px) {
    grid-template-columns: 1fr 15px 1fr 15px 1fr 15px 1fr 15px 1fr;
    column-gap: initial;
  }
  &.secondary {
    display: block;
    opacity: 0;
    position: absolute;
    bottom: 50px;
    z-index: -1;
  }

  &.active {
    opacity: 1;
    bottom: 0px;
    z-index: 1;
    // display: grid;
  }

  &.hide {
    .main-box-container {
      opacity: 0.3;
    }
  }
}

.plus,
.minus,
.equals {
  width: 30px;
  height: 30px;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #494e59;
  border-radius: 50%;
  font-size: 16px;
  font-weight: bold;

  @media screen and (max-width: 900px) {
    width: 10px;
    height: 10px;
    font-size: 6px;
  }
}
</style>