<template>
  <div class="secondary-box-container" :class="boxType">
    <div class="bordered-box">
      <div class="box-title">{{ title }}</div>
      <div class="box-data">
        <span class="dollar-sign">$</span>
        <span class="total">{{ mainData }}</span>
      </div>
    </div>
    <div class="highlight-box">{{ highlightData }}</div>
  </div>
</template>
  
  <script>
export default {
  name: "secondary-box",
  props: {
    title: "",
    mainData: "",
    highlightData: "",
    highlightText: "",
    boxType: "",
    type: ""
  }
};
</script>
  
  <style lang="less" scoped>
.secondary-box-container {
  pointer-events: none;
  max-width: 183px;
  margin: 0 auto;
  border-radius: 12px;
  text-align: center;
  aspect-ratio: 1;
  font-weight: bold;
  cursor: pointer;
  transition: 0.5s margin;
  user-select: none;

  &:active {
    margin: 4px;

    .bordered-box > .box-data {
      font-size: 40px;
    }
  }
  .bordered-box {
    height: 80%;
    padding: 8px 0;
    // margin: 2px;
    border-radius: 11px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-top: 0 !important;
    border-right: 0 !important;
    border-left: 0 !important;
    .box-title {
      font-size: 12px;
    }
    .box-data {
      display: flex;
      margin: auto;
      font-size: 38px;
      transition: 0.5s;

      span.dollar-sign {
        font-size: 32px;
      }
    }
  }
  .highlight-box {
    height: 15%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
  }

  @media screen and (max-width: 900px) {
    max-width: 140px;
    .bordered-box {
      .box-title {
        font-size: 8px;
      }
      .box-data {
        font-size: 12px;

        span.dollar-sign {
          font-size: 12px;
        }
      }
    }
    .highlight-box {
      font-size: 8px;
    }
  }

  @media screen and (max-width: 600px) {
    max-width: 62px;

    border-radius: 8px;
    .bordered-box {
      .box-title {
        font-size: 6px;
        padding: 0px 4px;
      }
      .box-data,
      .box-data-full {
        font-size: 10px;

        span.dollar-sign {
          font-size: 8px;
        }
      }

      .box-data-full {
        font-size: 7px;
        margin: -4px auto auto auto;

        span.dollar-sign {
          font-size: 7px;
        }
      }
    }
    .highlight-box {
      font-size: 4px;
      padding: 0px 4px;
      height: initial;
      .expand {
        margin-left: 8px;
      }
    }
  }
}
// .secondary-box-container {
//   max-width: 183px;
//   border-radius: 12px;
//   text-align: center;
//   aspect-ratio: 1;
//   font-weight: bold;
//   cursor: pointer;
//   transition: 0.5s margin;
//   user-select: none;

//   // &:active {
//   //   margin: 4px;

//   //   .bordered-box > .box-data {
//   //     font-size: 40px;
//   //   }
//   // }
//   .bordered-box {
//     height: 80%;
//     padding: 8px 0;
//     // margin: 2px;
//     border-radius: 11px;
//     display: flex;
//     flex-direction: column;
//     justify-content: space-between;
//     border-top: 0 !important;
//     border-right: 0 !important;
//     border-left: 0 !important;

//     .box-data {
//       margin-bottom: 30px;
//       font-size: 44px;
//       transition: 0.5s;
//     }
//   }
//   .highlight-box {
//     height: 15%;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//   }
// }
.dark-green {
  border: 2px solid #02734d;

  .bordered-box {
    border: 2px solid #02734d;
  }
}

.dark-red {
  border: 2px solid #902249;
  .bordered-box {
    border: 2px solid #902249;
  }
}

.dark-orange {
  border: 2px solid #a35501;

  .bordered-box {
    border: 2px solid #a35501;
  }
}

.dark-blue {
  border: 2px solid #026f8b;

  .bordered-box {
    border: 2px solid #026f8b;
  }
}
</style>